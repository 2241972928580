import React from 'react';

const Sidebar = () => {
  return (
    <div>
      <div className="sidebar right-sidebar"></div>

      <div className="sidebar left-sidebar"></div>
    </div>

  );
};

export default Sidebar;